











































import RequestService from "@/service/RequestService";
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import DeviceService from "../../service/DeviceService";

@Component
export default class HeaderComponent extends Vue {
  @Inject()
  private readonly requestService!: RequestService;

  @Inject()
  private readonly deviceService!: DeviceService;

  private readonly buttonsSectionActiveClassName =
    "HeaderComponent__buttons-section--active";
  private activePath: string = window.location.pathname;

  async created(): Promise<void> {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("orientationchange", this.onOrientationChange);
  }

  beforeDestroy(): void {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("orientationchange", this.onOrientationChange);
  }

  private onResize() {
    const navigation: HTMLElement = this.$refs.navigation as HTMLElement;
    if (navigation.classList.contains(this.buttonsSectionActiveClassName)) {
      this.showNavigation();
    }
  }

  private onOrientationChange() {
    this.hideNavigation();
  }

  highlightRoute(key: string): string {
    if (this.activePath.endsWith(`${key}`)) {
      return "HeaderComponent__route HeaderComponent__route--active";
    }

    return "HeaderComponent__route";
  }

  getRouteName(value: string): string | Record<string, string> {
    if (value.includes("translation:")) {
      return this.$store.state.general.translations[
        value.replace("translation:", "")
      ];
    }

    return value;
  }

  setActivePath(url: string): void {
    this.activePath = `/${url}`;
    this.changeNavigationVisibility(false);
    this.deviceService.enableScroll();
  }

  private changeNavigationVisibility(status: boolean) {
    const navigation: HTMLElement = this.$refs.navigation as HTMLElement;
    const hamburger: HTMLElement = this.$refs.hamburger as HTMLElement;
    const dim: HTMLElement = this.$refs.dim as HTMLElement;

    if (status) {
      navigation.classList.add(this.buttonsSectionActiveClassName);
      hamburger.classList.add("is-active");
      dim.classList.add("HeaderComponent__dim--active");
    } else {
      navigation.classList.remove(this.buttonsSectionActiveClassName);
      hamburger.classList.remove("is-active");
      dim.classList.remove("HeaderComponent__dim--active");
    }
  }

  private hideNavigation() {
    const header: HTMLElement = this.$refs.header as HTMLElement;
    const dim: HTMLElement = this.$refs.dim as HTMLElement;
    this.changeNavigationVisibility(false);
    this.deviceService.enableScroll();
    header.style.overflowY = "unset";
    dim.style.height = "100vh";
  }

  private showNavigation() {
    const navigation: HTMLElement = this.$refs.navigation as HTMLElement;
    const header: HTMLElement = this.$refs.header as HTMLElement;
    const dim: HTMLElement = this.$refs.dim as HTMLElement;
    this.changeNavigationVisibility(true);
    // if (header.getBoundingClientRect().height >= window.innerHeight) {
    window.scrollTo(0, 0);
    // }
    this.deviceService.disableScroll();
    if (this.deviceService.isElementOutOfViewport(navigation)) {
      dim.style.height = `${
        header.getBoundingClientRect().height - window.innerHeight
      }px`;
      header.style.overflowY = "auto";
    }
  }

  public handleNavigationVisibility(): void {
    const navigation: HTMLElement = this.$refs.navigation as HTMLElement;
    if (navigation.classList.contains(this.buttonsSectionActiveClassName)) {
      this.hideNavigation();
    } else {
      this.showNavigation();
    }
  }

  @Watch("$route")
  public onRouteChange(): void {
    this.activePath = window.location.pathname;
  }
}
